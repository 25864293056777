header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 108px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  header .user {
    margin-left: auto;
    padding: 0 64px;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      header .user {
        padding: 0 32px; } }
    header .user > i {
      color: #922c88;
      font-size: 32px; }
    header .user span {
      color: #303030;
      font-size: 14px;
      margin-right: 8px; }
    header .user img {
      width: 48px;
      height: 48px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        header .user img {
          width: 32px;
          height: 32px; } }
    header .user::after {
      content: ' ';
      display: inline-block;
      vertical-align: .255em;
      margin-left: .255em;
      width: 3px;
      height: 3px;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent; }
    header .user .menu {
      position: absolute;
      top: 0;
      right: 0;
      will-change: transform;
      transform: translate3d(-60px, 90px, 0px);
      width: 160px;
      border: 1px solid #d7d7d7;
      background-color: #fff;
      margin: 0;
      list-style: none;
      padding: 8px 0px; }
      header .user .menu > li {
        padding: .75rem 1.5rem;
        color: #303030;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center; }
        header .user .menu > li i {
          font-size: 24px;
          margin-right: 8px; }
        header .user .menu > li:hover {
          background-color: #f3f3f3; }
  header .hamburger {
    display: flex;
    justify-content: center;
    color: #922c88;
    font-size: 32px;
    width: 128px;
    cursor: pointer; }
  header .logo {
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px; }
    header .logo svg {
      width: 200px; }
  header .language .menu {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translate3d(65px, 90px, 0px);
    width: 160px;
    border: 1px solid #d7d7d7;
    background-color: #fff;
    margin: 0;
    list-style: none;
    padding: 8px 0px; }
    header .language .menu > li {
      padding: .75rem 1.5rem;
      color: #303030;
      font-size: 12px;
      cursor: pointer; }
      header .language .menu > li img {
        width: 24px;
        margin-right: 8px; }
      header .language .menu > li:hover {
        background-color: #f3f3f3; }
  header .language button {
    background-color: #f8f8f8 !important;
    outline: none;
    color: #8f8f8f; }
    header .language button img {
      width: 24px;
      margin-right: 8px; }
    header .language button:active {
      background-color: #dae0e5 !important;
      outline: none; }
    header .language button:focus {
      background-color: #dae0e5 !important;
      outline: none; }
    header .language button::after {
      content: ' ';
      display: inline-block;
      vertical-align: .255em;
      margin-left: .255em;
      width: 3px;
      height: 3px;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent; }
  @media only screen and (max-width: 768px) {
    header {
      background-color: #fff;
      height: 80px; }
      header .logo {
        width: 64px;
        position: absolute;
        left: 50%;
        margin-left: -32px;
        height: 32px; }
        header .logo svg {
          width: 64px;
          height: 32px; }
      header .hamburger {
        display: flex;
        justify-content: center;
        color: #922c88;
        font-size: 26px;
        width: 64px; } }

.animate-sidebar {
  animation-name: sidebar-animation;
  animation-duration: 0.5s; }

.animate-submenu {
  animation-name: subsidebar-animation;
  animation-duration: 0.5s; }

.sidebar {
  width: 128px;
  float: left;
  height: 100%;
  min-height: calc(100vh - 128px);
  background-color: #fff;
  position: fixed;
  top: 0;
  margin-top: 108px;
  left: 0;
  padding-bottom: 108px;
  overflow-y: auto;
  z-index: 3; }
  @media only screen and (max-width: 768px) {
    .sidebar {
      top: 0px;
      margin-top: 64px; } }
  .sidebar > ul {
    list-style: none;
    margin: 0;
    text-align: center;
    z-index: 99;
    padding: 0; }
    .sidebar > ul > li {
      padding: 16px;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      cursor: pointer; }
      .sidebar > ul > li img {
        width: 100%;
        padding: 8px 32px; }
      .sidebar > ul > li:hover {
        border-left: 6px solid #922c88; }
        .sidebar > ul > li:hover > a > span {
          color: #922c88; }
        .sidebar > ul > li:hover > a > i {
          color: #922c88; }
      .sidebar > ul > li > a {
        width: 100%;
        color: #fff; }
        .sidebar > ul > li > a > span {
          color: #303030; }
        .sidebar > ul > li > a i {
          width: 100%;
          float: left;
          font-size: 26px;
          color: #303030; }
      .sidebar > ul > li:hover {
        background-color: #f3f3f3; }
      .sidebar > ul > li .sub-menu {
        position: fixed;
        top: 108px;
        left: 128px;
        height: calc(100vh - 108px);
        width: 230px;
        background-color: #fff;
        margin: 0;
        list-style: none;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
        z-index: 5;
        padding: 0; }
        @media only screen and (max-width: 768px) {
          .sidebar > ul > li .sub-menu {
            top: 80px;
            height: calc(100vh - 80px); } }
        .sidebar > ul > li .sub-menu > li {
          width: 100%;
          float: left; }
          .sidebar > ul > li .sub-menu > li > a {
            width: 100%;
            float: left;
            padding: 16px;
            text-align: left;
            color: #303030;
            display: flex;
            align-items: center; }
            .sidebar > ul > li .sub-menu > li > a i {
              color: #303030;
              font-size: 24px;
              margin-right: 8px; }
            .sidebar > ul > li .sub-menu > li > a:hover {
              color: #922c88; }
              .sidebar > ul > li .sub-menu > li > a:hover i {
                color: #922c88; }

@keyframes modal-animation {
  from {
    top: 0; }
  to {
    top: 50%; } }

@keyframes sidebar-animation {
  from {
    left: -100px; }
  to {
    left: 0; } }

@keyframes subsidebar-animation {
  from {
    left: 0px;
    z-index: -3; }
  to {
    left: 128px;
    z-index: -3; } }

.sidebar-hide {
  display: none; }

.content {
  width: 100%;
  min-height: calc(100vh - 108px);
  background-color: #f6f6f6;
  padding: 16px 32px;
  /*-webkit-box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);
    -moz-box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);
    box-shadow: inset 0px 5px 5px 1px rgba(184,184,184,0.63);*/
  margin-top: 108px; }
  @media only screen and (max-width: 768px) {
    .content {
      padding: 0px;
      margin-top: 64px;
      min-height: calc(100vh - 64px); } }
  .content .panel {
    margin: 16px 0px; }
    .content .panel .panel-title {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 16px;
      width: 100%; }
      .content .panel .panel-title > h1 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        width: 100%; }
      .content .panel .panel-title .button-wrap {
        text-align: right;
        margin-top: 16px; }
        @media only screen and (max-width: 768px) {
          .content .panel .panel-title .button-wrap {
            font-size: 12px;
            padding-left: 0 !important;
            padding-right: 0 !important; } }
      .content .panel .panel-title > h6 {
        width: 100%;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #b0b0b0; }
    .content .panel .container-title {
      width: 100%;
      margin-bottom: 16px; }
      .content .panel .container-title > h1 {
        font-size: 18px;
        margin-bottom: 4px;
        width: 100%; }
      .content .panel .container-title > h6 {
        width: 100%;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #b0b0b0; }
    .content .panel > div {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #fff;
      -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
      @media only screen and (max-width: 768px) {
        .content .panel > div {
          padding-left: 0 !important;
          padding-right: 0 !important; } }
      .content .panel > div .title {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 16px; }
        @media only screen and (max-width: 768px) {
          .content .panel > div .title {
            padding-top: 15px; } }
        .content .panel > div .title h1 {
          font-size: 24px;
          margin-bottom: 10px;
          font-weight: 600;
          padding-left: 0 !important; }
        .content .panel > div .title .button {
          margin-left: auto;
          margin-right: 0; }
        .content .panel > div .title > div {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          padding: 0; }
          @media only screen and (max-width: 768px) {
            .content .panel > div .title > div {
              padding: 0 15px; } }
        .content .panel > div .title h6 {
          padding-left: 0 !important;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 10px;
          color: #b0b0b0; }
  .content .panel-list > div {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.sidebar-active {
  padding-left: 128px !important;
  width: 100%; }

.sub-menu-active {
  padding-left: 350px;
  width: 100%; }

.input-wrap {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  float: left; }
  .input-wrap label {
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;
    color: #303030; }
  .input-wrap input[type=text], .input-wrap select, .input-wrap textarea {
    width: 100%;
    border: 1px solid #d7d7d7;
    padding: 8px;
    height: 36px;
    float: left; }
    .input-wrap input[type=text]:focus, .input-wrap select:focus, .input-wrap textarea:focus {
      border: 1px solid #922c88;
      outline: none; }
  .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    float: left; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #922c88;
      border-bottom: 3px solid #922c88;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .input-wrap textarea {
    height: 100px; }
  .input-wrap .select-field {
    border: 1px solid #d7d7d7;
    min-width: 100%;
    padding: 0;
    height: 36px;
    font-size: 14px; }
    .input-wrap .select-field a {
      color: #303030; }
    .input-wrap .select-field .dropdown-menu {
      min-width: 100%;
      max-height: 200px;
      overflow-y: auto; }
    .input-wrap .select-field .dropdown-toggle {
      height: 36px;
      float: left;
      width: 100%; }
      .input-wrap .select-field .dropdown-toggle::after {
        display: none; }
      .input-wrap .select-field .dropdown-toggle::before {
        content: " ";
        display: block;
        border-top: 2px solid #303030;
        border-right: 2px solid #303030;
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
        float: right;
        margin-top: 4px; }
  .input-wrap .google-map-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 42px;
    margin-top: -63px;
    margin-left: -21px;
    color: #922c88; }
  .input-wrap .file-drop {
    border: 1px dotted #d7d7d7;
    width: 100%;
    height: 500px;
    position: relative;
    overflow-y: auto; }
    .input-wrap .file-drop .dropzone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /*margin-left: -32px;
            margin-top: -32px;
            */ }
      .input-wrap .file-drop .dropzone input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .input-wrap .file-drop .dropzone > button {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -32px;
        margin-top: -32px;
        border: 0;
        background-color: transparent; }
        .input-wrap .file-drop .dropzone > button i {
          font-size: 64px;
          color: #922c88; }
    .input-wrap .file-drop .preview {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
      .input-wrap .file-drop .preview .options {
        text-align: center;
        width: 50%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 25%;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center; }
        .input-wrap .file-drop .preview .options > i {
          font-size: 64px;
          cursor: pointer;
          display: none;
          color: rgba(146, 44, 136, 0.2); }
        .input-wrap .file-drop .preview .options:hover {
          background-color: rgba(255, 255, 255, 0.8); }
          .input-wrap .file-drop .preview .options:hover > i {
            display: block;
            color: #922c88; }
      .input-wrap .file-drop .preview img {
        width: 50%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 25%;
        object-fit: contain;
        object-position: center; }
    .input-wrap .file-drop .grid-layout {
      position: absolute;
      top: 0;
      left: 0;
      height: 650px; }
      .input-wrap .file-drop .grid-layout .image-wrap {
        border: 1px dotted #d7d7d7;
        height: 150px;
        position: relative;
        background-color: #f6f6f6; }
        .input-wrap .file-drop .grid-layout .image-wrap:hover .options {
          /*.mdi-delete{
                        color: rgb(146, 44, 136);
                    }
                    .mdi-home{
                        color: rgb(146, 44, 136);
                    }*/ }
        .input-wrap .file-drop .grid-layout .image-wrap:hover .primary > i:nth-child(1) {
          color: #e3b110; }
        .input-wrap .file-drop .grid-layout .image-wrap .info {
          padding: 16px;
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .input-wrap .file-drop .grid-layout .image-wrap .info input {
            margin-bottom: 8px; }
          .input-wrap .file-drop .grid-layout .image-wrap .info button {
            margin: 0; }
        .input-wrap .file-drop .grid-layout .image-wrap .primary > i:nth-child(1) {
          color: #e3b110; }
        .input-wrap .file-drop .grid-layout .image-wrap .options {
          text-align: center;
          width: 100%; }
          .input-wrap .file-drop .grid-layout .image-wrap .options > i {
            font-size: 32px;
            padding: 8px;
            cursor: pointer; }
          .input-wrap .file-drop .grid-layout .image-wrap .options .mdi-delete {
            color: rgba(146, 44, 136, 0.2); }
          .input-wrap .file-drop .grid-layout .image-wrap .options .mdi-home {
            color: #4556ac; }
        .input-wrap .file-drop .grid-layout .image-wrap .mdi-delete:hover {
          color: #922c88; }
        .input-wrap .file-drop .grid-layout .image-wrap .mdi-home:hover {
          color: #922c88; }
        .input-wrap .file-drop .grid-layout .image-wrap img {
          width: 100%;
          height: 100px;
          object-fit: contain;
          object-position: center; }
    .input-wrap .file-drop .gallery-grid .image-wrap {
      height: 300px; }

.search-wrap {
  position: relative; }
  .search-wrap input[type=text] {
    border-radius: 35px; }
  .search-wrap .mdi {
    position: absolute;
    right: 10px;
    top: 3px;
    color: #922c88;
    font-size: 20px; }
  .search-wrap .search-results {
    padding: 16px 0;
    border: 1px solid #d7d7d7;
    position: relative;
    top: 40px;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column; }
    .search-wrap .search-results .row {
      cursor: pointer;
      padding: 3px 0; }
      .search-wrap .search-results .row:hover {
        background-color: #f1f1f1; }
    .search-wrap .search-results img {
      width: 32px;
      height: 32px; }
    .search-wrap .search-results span {
      padding: 0 16px; }

.list-view {
  padding: 16px 0;
  border: 1px solid #d7d7d7;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column; }
  .list-view .row {
    cursor: pointer;
    padding: 3px 0; }
    .list-view .row:hover {
      background-color: #f1f1f1; }
    .list-view .row > div {
      align-items: center;
      display: flex; }
  .list-view img {
    width: 32px;
    height: 32px; }
  .list-view .mdi-delete {
    font-size: 24px;
    color: #922c88; }

.sort-wrap {
  position: relative;
  width: 100%; }
  .sort-wrap .select-field {
    margin-left: 15px;
    width: 200px;
    min-width: 200px;
    border-radius: 35px;
    background-color: #fff; }
    @media only screen and (max-width: 768px) {
      .sort-wrap .select-field {
        margin-left: 0; } }

.login-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0; }
  .login-page .modal {
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    display: block;
    margin-top: -150px;
    margin-left: -150px;
    padding: 32px; }
    .login-page .modal svg {
      width: 100%;
      padding: 0 16px; }
    .login-page .modal .input-wrap {
      text-align: center; }
      .login-page .modal .input-wrap input[type=text], .login-page .modal .input-wrap input[type=password] {
        border: 1px solid #d7d7d7;
        width: 100%;
        padding: 8px;
        height: 36px; }
        .login-page .modal .input-wrap input[type=text]:focus, .login-page .modal .input-wrap input[type=password]:focus {
          border: 1px solid #922c88;
          outline: none; }
      .login-page .modal .input-wrap .button {
        padding: 8px 32px;
        border-radius: 20px;
        width: 100%; }

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em; }
  @media only screen and (max-width: 768px) {
    table {
      overflow-x: scroll;
      display: block; } }
  table > tr {
    width: 100%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    margin: 8px 0px;
    background-color: #fff;
    height: 64px;
    /*&:nth-child(even){
            background-color: #f6f6f6;
        }*/ }
    @media only screen and (max-width: 768px) {
      table > tr {
        height: 164px; } }
    table > tr:first-child {
      height: 32px; }
    table > tr > th {
      height: 32px;
      padding: 10px 0 !important; }
      table > tr > th:first-child {
        padding: 10px 10px !important; }
    table > tr .action-head {
      text-align: right; }
    table > tr > td {
      height: 64px;
      padding: 20px 0 !important; }
      @media only screen and (max-width: 768px) {
        table > tr > td {
          padding: 0 4px; } }
      table > tr > td:first-child {
        padding: 0 !important; }
      table > tr > td img {
        width: 64px;
        height: 64px;
        object-fit: cover; }
        @media only screen and (max-width: 768px) {
          table > tr > td img {
            display: none; } }
      table > tr > td input[type=text], table > tr > td input[type=number] {
        border: none;
        background-color: transparent;
        cursor: pointer; }
      table > tr > td input[type=text]:focus {
        border: 1px solid #922c88;
        outline: none;
        cursor: text; }
      table > tr > td .number-input {
        width: 40px; }
      table > tr > td .button {
        margin-bottom: 8px;
        background-color: transparent !important;
        font-size: 16px !important;
        height: 44px; }
        table > tr > td .button .mdi {
          color: #922c88;
          font-size: 24px; }
        table > tr > td .button > img {
          width: 26px;
          height: 26px; }
    table > tr .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 64px; }
      @media only screen and (max-width: 768px) {
        table > tr .action {
          flex-wrap: wrap;
          height: 164px !important; } }
  table .text-row td, table .text-row th {
    padding: 0 16px; }
  table .text-row:first-child {
    background-color: transparent;
    height: 40px;
    box-shadow: none; }

.table {
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .table {
      padding: 0 15px; } }

.table-no-image table {
  border-spacing: 0 8px; }
  .table-no-image table > tr {
    height: 32px; }
    .table-no-image table > tr > th {
      height: 32px;
      padding: 10px 0 !important; }
      .table-no-image table > tr > th:first-child {
        padding: 10px 10px !important; }
    .table-no-image table > tr > td {
      height: 32px;
      padding: 10px 0 !important; }
      .table-no-image table > tr > td:first-child {
        padding: 10px 10px !important; }

.pagination {
  float: left; }
  .pagination > li {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer; }
    .pagination > li a {
      color: #303030;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .pagination > li:hover {
      color: #fff;
      text-decoration: none;
      background-color: #2e3e50; }
      .pagination > li:hover a {
        text-decoration: none;
        color: #fff; }
  .pagination .break-me:hover {
    background-color: #E5E5E5;
    cursor: default; }
    .pagination .break-me:hover a {
      color: #303030; }
  .pagination .active {
    color: #fff;
    background-color: #2e3e50; }
    .pagination .active a {
      color: #fff; }
  .pagination .previous {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .previous::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #303030;
      border-left: 2px solid #303030;
      padding: 4px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 16px;
      left: 16px; }
    .pagination .previous:hover::after {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff; }
  .pagination .next {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .next::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #303030;
      border-left: 2px solid #303030;
      padding: 4px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 16px;
      left: 16px; }
    .pagination .next:hover::after {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff; }

.button {
  background-color: #922c88;
  border-radius: 35px;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  margin-right: 15px;
  border: 0; }
  .button > img {
    width: 20px; }

.prompt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 53, 68, 0.5);
  z-index: 999999999; }
  .prompt-modal > div {
    width: 400px;
    -webkit-box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    animation-name: modal-animation;
    animation-duration: 0.3s;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -300px;
    background-color: #fff;
    padding-bottom: 16px;
    text-align: center; }
    .prompt-modal > div > h3 {
      font-size: 17px;
      width: 100%;
      text-align: left;
      padding: 16px; }
      .prompt-modal > div > h3 > i {
        font-size: 18px;
        color: #922c88;
        float: right;
        color: #d7d7d7;
        cursor: pointer; }
    .prompt-modal > div .spacer {
      margin: 0 0 16 0px; }
    .prompt-modal > div .button-wrap {
      text-align: right; }
      .prompt-modal > div .button-wrap .button {
        padding-left: 32px;
        padding-right: 32px; }
        .prompt-modal > div .button-wrap .button:nth-child(2) {
          background-color: #4556ac;
          border-color: #4556ac;
          color: #fff; }
    .prompt-modal > div .input-wrap {
      padding: 16px; }

.multilang-field {
  width: 100%;
  height: 40px;
  list-style: none;
  padding: 0;
  margin: 0;
  border-left: 1px solid #d7d7d7; }
  .multilang-field li {
    float: left;
    padding: 8px 16px;
    background-color: #f6f6f6;
    border-top: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    cursor: pointer;
    text-transform: uppercase; }
    .multilang-field li:hover {
      background-color: #fff;
      color: #922c88;
      font-weight: 600; }
  .multilang-field .active {
    background-color: #fff;
    color: #922c88;
    font-weight: 600; }

.spacer {
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
  margin: 32px 0; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 53, 68, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999; }

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px; }

.lds-ripple div {
  position: absolute;
  border: 8px solid #922c88;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 56px;
    left: 56px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 120px;
    height: 120px;
    opacity: 0; } }

.react-switch {
  height: 20px !important;
  width: 44px !important; }
  .react-switch > span {
    height: 24px !important;
    width: 24px !important;
    top: -4px !important; }

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); }

.spinner {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #922c88;
  background: -moz-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #922c88 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.spinner:before {
  width: 50%;
  height: 50%;
  background: #922c88;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.spinner:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Barlow', sans-serif;
  background-color: #f6f6f6; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Barlow', sans-serif; }

button {
  cursor: pointer; }

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D7D7D7;
  border-radius: 5px;
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D7D7D7;
  cursor: pointer; }
